// Import scripts and styles
import './scripts';
import './styles/index.scss';

// Import all images
const imagesCtx = require.context('./images', false, /\.(png|jpg|jpeg|gif|ico|svg|webp)$/);
imagesCtx.keys().forEach(imagesCtx);


import { Application } from "@hotwired/stimulus"
import { definitionsFromContext } from "@hotwired/stimulus-webpack-helpers"

window.Stimulus = Application.start()
window.Stimulus.debug = process.env.NODE_ENV === 'development'

const context = require.context("./controllers", true, /\.js$/)
window.Stimulus.load(definitionsFromContext(context))
