import '../plugins/fancybox/jquery.fancybox.min.js';

document.addEventListener('DOMContentLoaded', () => {

    // Initialize the product features
    Array.from(document.querySelectorAll('[data-product-features-more]')).forEach(more => {
        more.addEventListener('click', e => {
            e.preventDefault();
            more.parentElement.style.display = 'none';
            document.querySelector(`[data-product-features-hidden="${more.dataset.productFeaturesMore}"]`).style.display = 'flex';
        });
    });

    // Initialize the course more buttons
    Array.from(document.querySelectorAll('[data-courses-more-trigger]')).forEach(trigger => {
        trigger.addEventListener('click', e => {
            e.preventDefault();
            trigger.parentElement.style.display = 'none';

            Array.from(document.querySelector(`[data-courses-more-list="${trigger.dataset.coursesMoreTrigger}"]`).querySelectorAll('.course--hidden')).forEach(course => {
                course.classList.remove('course--hidden');
            });
        });
    });

    // Initialize the product videos
    Array.from(document.querySelectorAll('[data-product-videos]')).forEach(el => {
        jQuery(el).find('[data-fancybox]').fancybox({
            buttons: ['thumbs', 'close'],
            thumbs: {
                autoStart: true,
                hideOnClose: false,
                axis: 'x',
            },
            caption: function() {
                return this.title;
            }
        });
    });
});
