import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
    static classes = ['body'];
    static targets = ['trigger', 'content'];

    connect () {
        this.visible = false;

        // Hide the content on document click
        document.addEventListener('click', e => {
            if (this.visible && document.body.classList.contains(this.bodyClass) && !this.element.contains(e.target)) {
                e.preventDefault();
                this.visible = false;
                document.body.classList.remove(this.bodyClass);
            }
        });
    }

    open (e) {
        e.preventDefault();

        if (!document.body.classList.contains(this.bodyClass)) {
            setTimeout(() => {
                document.body.classList.add(this.bodyClass);
                this.visible = true;
            }, 100);
        }
    }
}
